<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="left_drawer" clipped app>
      <!-- <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar>
            <img src="https://randomuser.me/api/portraits/women/81.jpg" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>Jane Smith</v-list-item-title>
            <v-list-item-subtitle>Logged In</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider></v-divider> -->

      <v-list dense nav>
        <v-list-item v-for="item in items" :key="item.title" :to="item.to">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="right_drawer" app clipped right>
      <v-list-item>
        <v-list-item-content class="text-center">
          <v-list-item-title class="title mt-6">
            <v-avatar size="78">
              <img :src="require('./assets/avatar.png')" alt="John" />
            </v-avatar>
          </v-list-item-title>
          <v-list-item-subtitle class="my-6">
            {{ authUser.name.toUpperCase() }}
          </v-list-item-subtitle>
          <v-list-item-subtitle class="">
            <v-chip class="ma-2" color="success" outlined label>
              <v-icon left>
                mdi-server-plus
              </v-icon>
              {{ authUser.type.toUpperCase() }}
            </v-chip>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <!-- <v-list dense nav>
        <v-list-item v-for="item in items" :key="item.title" link>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list> -->

      <template v-slot:append>
        <div class="pa-2">
          <v-btn block color="error">
            Logout
          </v-btn>
        </div>
      </template>

      <!-- <div class="bottom-navigation">
        <div class="pa-2">
          <v-btn block color="error">
            Logout
          </v-btn>
        </div> -->
    </v-navigation-drawer>

    <v-app-bar
      app
      flat
      clipped-right
      clipped-left
      height="58px"
      color="#FFFFFF"
      class="app-bar"
    >
      <v-app-bar-nav-icon
        @click="left_drawer = !left_drawer"
      ></v-app-bar-nav-icon>

      <v-toolbar-title>MOHE</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn icon>
        <v-icon size="20">mdi-bell-outline</v-icon>
      </v-btn>
      <!-- <v-btn icon color="pink">
        <v-icon size="20">mdi-heart</v-icon>
      </v-btn> -->
      <v-btn icon @click="right_drawer = !right_drawer">
        <v-avatar size="28">
          <img :src="require('./assets/avatar.png')" alt="Sashika" />
        </v-avatar>
      </v-btn>
    </v-app-bar>

    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container class="main-container">
        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>

    <bottom-navigation></bottom-navigation>
  </v-app>
</template>

<script>
import BottomNavigation from "@/components/app/BottomNavigation.vue";

export default {
  components: {
    BottomNavigation,
  },
  data: () => ({
    left_drawer: false,
    right_drawer: false,
    value: 1,
    items: [
      { title: "Home", icon: "mdi-home-outline", to: "/" },
      { title: "MOU", icon: "mdi-notebook-edit-outline", to: "/mous" },
      // { title: "COUNTRIES", icon: "mdi-fireplace-off", to: "/countries" },
      {
        title: "INSTITUTES",
        icon: "mdi-home-city-outline",
        to: "/institutes",
      },
      {
        title: "APPROVERS",
        icon: "mdi-certificate-outline",
        to: "/approvers",
      },
      { title: "USERS", icon: "mdi-account-group-outline", to: "/users" },
      { title: "GUEST", icon: "mdi-account-search-outline", to: "/guest" },
    ],
  }),
  computed: {
    authUser() {
      return this.$store.getters["auth/authUser"];
    },
  },
  created: function() {
    this.$store.dispatch("mous/getMous");
  },
};
</script>

<style scoped>
.app-bar {
  /* border-bottom: 1px solid rgb(187, 187, 187) !important; */
  box-shadow: 0 2px 8px #ececec !important;
}

.border {
  border: 1px solid #ebedf0;
}

.bottom-navigation {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding-bottom: 64px;
}

/* * {
  font-family: "Share Tech Mono", monospace;
} */

/* * {
  font-family: "Montserrat", sans-serif;
} */
</style>
