import api from "@/api";

export default {
  namespaced: true,

  state: () => ({
    accessToken: null,
    refreshToken: null,
    user: {
      _id: "629398c889a35704c5d21432",
      email: "ireshabandaranayake@gmail.com",
      username: "iresha",
      name: "iresha bandaranayake",
      type: "administrator",
      authBy: "username",
      createdAt: "2022-05-29T16:01:12.897Z",
      accessToken:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NTM4NDAxNDMsImV4cCI6MTY4NTM5Nzc0MywiYXVkIjoiNjI5Mzk4Yzg4OWEzNTcwNGM1ZDIxNDMyIiwiaXNzIjoiaHR0cHM6Ly9zYXNoaWthLm9yZyJ9.srJkTPDJwc7kIbTV9_WVa1wA_eZSICKoRNbLNYgwoEU",
      refreshToken:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NTM4NDAxNDMsImV4cCI6MTY4NTM5Nzc0MywiYXVkIjoiNjI5Mzk4Yzg4OWEzNTcwNGM1ZDIxNDMyIiwiaXNzIjoiaHR0cHM6Ly9zYXNoaWthLm9yZyJ9.wWcSTxTuzLFUYi0EF9Qt5C7FXaUFeFmCwHFQVVr9PEQ",
    },
  }),

  mutations: {
    SET_TOKENS(state, payload) {
      state.accessToken = payload.access_token;
      state.refreshToken = payload.refresh_token;
    },

    SET_AUTH_USER_DETAILS(state, payload) {
      console.log(payload);
      const user = {
        _Id: payload.id,
        username: payload.username,
        name: payload.name,
        email: payload.email,
        institute: payload.institute,
      };
      state.user = user;
    },

    SET_BEARER_TOKEN(state, payload) {},
  },

  actions: {
    async login({ commit, dispatch }, credentials) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "login",
          },
          { root: true }
        );

        const response = await api.post("auth/login", credentials);

        const tokens = response.data.data;

        commit("SET_TOKENS", tokens);

        dispatch("attempt", tokens.access_token);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          { name: "login" },
          { root: true }
        );
      } catch (error) {
        console.log(error);

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "login",
            error: error,
          },
          { root: true }
        );
      }
    },

    async attempt({ commit }, accessToken) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          { name: "authUserDetails" },
          { root: true }
        );

        const response = await api.get("users/me", {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        });
        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          { name: "authUserDetails" },
          { root: true }
        );
        console.log(response.data.data);
        commit("SET_AUTH_USER_DETAILS", response.data.data);
      } catch (error) {
        console.log(error);
        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "authUserDetails",
            error: error,
          },
          { root: true }
        );
      }
    },

    async registerUser({ commit }, payload) {
      console.log(payload);
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "registerUser",
          },
          { root: true }
        );

        const response = await api.post("auth/register", payload);

        commit("ADD_USER_TO_USERS", response.data.data, { root: true });

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "registerUser",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "registerUser",
            error: "Server Error",
          },
          { root: true }
        );
      }
    },

    logout({ commit }) {
      firebase.auth().signOut();
      commit("setUser", null);
    },
  },
  getters: {
    authUser(state) {
      return state.user;
    },
  },
};
