export default {
  namespaced: true,
  state: {
    institutes: [
      {
        id: 1,
        title: "University of Colombo",
        submit_date: "2021/10/12",
        university: "UOC",
        reference_no: "1",
        mous: 20,
        grants: 8,
        country: "lk",
        mouApproved: 4,
        mouAmendments: 7,
        mouProceeding: 10,
        mouInMohe: 8,
      },
      {
        id: 2,
        title: "University of Peradeniya",
        submit_date: "2021/01/12",
        university: "UOP",
        reference_no: "2",
        mous: 12,
        grants: 22,
        country: "gb",
        mouApproved: 5,
        mouAmendments: 17,
        mouProceeding: 20,
        mouInMohe: 8,
      },
      {
        id: 3,
        title: "University of Moratuwa",
        submit_date: "2021/02/12",
        university: "UOM",
        reference_no: "3",
        mous: 31,
        grants: 14,
        country: "ag",
        mouApproved: 20,
        mouAmendments: 1,
        mouProceeding: 4,
        mouInMohe: 14,
      },
      {
        id: 4,
        title: "University of Ruhuna",
        submit_date: "2021/02/12",
        university: "UOR",
        reference_no: "4",
        mous: 52,
        grants: 32,
        country: "in",
        mouApproved: 6,
        mouAmendments: 7,
        mouProceeding: 12,
        mouInMohe: 1,
      },
      {
        id: 5,
        title: "University of Sri Jayawardhanapura",
        submit_date: "2021/03/12",
        university: "USJ",
        reference_no: "5",
        mous: 43,
        grants: 24,
        country: "fr",
        mouApproved: 4,
        mouAmendments: 7,
        mouProceeding: 10,
        mouInMohe: 8,
      },
    ],
  },
  mutations: {
    SET_INSTITUTES(state, payload) {
      state.institute = payload;
    },
  },
  actions: {
    async getInstitutes({ commit }) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "getInstitutes",
          },
          { root: true }
        );
        const response = await api.get("institutess");

        commit("SET_INSTITUTES", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "getInstitutes",
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "getInstitutes",
            error: error,
          },
          { root: true }
        );
      }
    },
  },
  getters: {
    institutes(state) {
      return state.institutes;
    },
  },
};
