import api from "@/api";

export default {
  namespaced: true,
  state: {
    approvers: [],
    approver: {
      _id: "",
      name: "",
      code: "",
      createdBy: "",
    },
    addApproverDialogVisibility: false,
  },
  mutations: {
    SET_APPROVERS(state, payload) {
      state.approvers = payload;
    },

    ADD_APPROVER_TO_APPROVERS(state, payload) {
      const approvers = [payload, ...state.approvers];
      state.approvers = approvers;
    },

    SET_ADD_APPROVER_DIALOG_VISIBILITY(state, payload) {
      state.addApproverDialogVisibility = payload;
    },
  },
  actions: {
    async getApprovers({ commit }) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "getApprovers",
          },
          { root: true }
        );
        const response = await api.get("approvers");

        commit("SET_APPROVERS", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "getApprovers",
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "getApprovers",
            error: error,
          },
          { root: true }
        );
      }
    },
    async createApprover({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "createApprover",
          },
          { root: true }
        );

        const response = await api.post("approvers", payload);

        commit("ADD_APPROVER_TO_APPROVERS", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "createApprover",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "createApprover",
            error: error,
          },
          { root: true }
        );
      }
    },

    setAddApproverDialogVisibility({ commit }, payload) {
      commit("SET_ADD_APPROVER_DIALOG_VISIBILITY", payload);
    },
  },
  getters: {
    approvers(state) {
      return state.approvers;
    },
    addApproverDialogVisibility(state) {
      return state.addApproverDialogVisibility;
    },
  },
};
