import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import app from "./modules/app";

import auth from "./modules/auth";

import users from "./modules/users";

import mou from "./modules/mou";
import mous from "./modules/mous";

import university from "./modules/university";
import universities from "./modules/universities";

import institute from "./modules/institute";
import institutes from "./modules/institutes";

import approver from "./modules/approver";
import approvers from "./modules/approvers";

import country from "./modules/country";
import countries from "./modules/countries";

export default new Vuex.Store({
  modules: {
    app,
    auth,
    users,
    mou,
    mous,
    university,
    universities,
    institute,
    institutes,
    approver,
    approvers,
    country,
    countries,
  },
});
