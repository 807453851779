<template>
  <v-container class="">
    <v-breadcrumbs :items="items"></v-breadcrumbs>
    <v-row class="text-center">
      <v-col cols="12">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          class="ma-6"
          placeholder="REFFERENCE NUMBER"
          outlined
        ></v-text-field>
      </v-col>

      <HelloWorld msg="Welcome to Your Vue.js App" />
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {
    HelloWorld,
  },
  data: () => ({
    search: "",
    items: [
      {
        text: "DASHBOARD",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
  }),
};
</script>
