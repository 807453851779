import api from "@/api";

export default {
  namespaced: true,

  state: () => ({
    user: {
      name: "",
      type: "",
      createdAt: "",
    },
    users: [],
    addUserDialogVisibility: false,
  }),

  mutations: {
    SET_USERS(state, payload) {
      state.users = payload;
    },
    ADD_USER_TO_USERS(state, payload) {
      const users = [payload, ...state.users];
      state.users = users;
    },

    SET_USER(state, payload) {
      state.user = payload;
    },

    SET_ADD_USER_DIALOG_VISIBILITY(state, payload) {
      state.addUserDialogVisibility = payload;
    },
  },

  actions: {
    async getUsers({ commit }) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "getUsers",
          },
          { root: true }
        );
        const response = await api.get("users");
        commit("SET_USERS", response.data.data);
        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "getUsers",
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "getUsers",
            error: error,
          },
          { root: true }
        );
      }
    },

    async createUser({ commit }, payload) {
      console.log(payload);
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "createUser",
          },
          { root: true }
        );

        const response = await api.post("auth/register", payload);

        commit("ADD_USER_TO_USERS", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "createUser",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "createUser",
            error: error,
          },
          { root: true }
        );
      }
    },

    async getUserById({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "getUserById",
          },
          { root: true }
        );
        console.log(payload);

        const response = await api.get(`users/${payload}`);

        commit("SET_USER", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "getUserById",
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "getUserById",
            error: error,
          },
          { root: true }
        );
      }
    },

    setAddUserDialogVisibility({ commit }, payload) {
      commit("SET_ADD_USER_DIALOG_VISIBILITY", payload);
    },

    logout({ commit }) {
      firebase.auth().signOut();
      commit("setUser", null);
    },

    registerUserForMeetup({ commit, getters }, payload) {
      commit("setLoading", true);
      const user = getters.user;
    },
  },
  getters: {
    user: (state) => {
      return state.user;
    },
    users: (state) => {
      return state.users;
    },
    get_user_by_id: (state) => (id) => {
      return state.users.find((users) => user.id === id);
    },
    addUserDialogVisibility(state) {
      return state.addUserDialogVisibility;
    },
  },
};
