export default {
  namespaced: true,

  state: () => ({
    authUser: {
      _id: "62893571be4e9cc149ca9d57",
      email: "iamdilini@hotmail.com",
      username: "iamdilini",
      name: "dilini wickramarathne",
      type: "user",
      authBy: "username",
      createdAt: "2022-05-21T18:54:41.071Z",
      accessToken:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NTMyOTk5MzcsImV4cCI6MTY4NDg1NzUzNywiYXVkIjoiNjI4OTM1NzFiZTRlOWNjMTQ5Y2E5ZDU3IiwiaXNzIjoiaHR0cHM6Ly9zYXNoaWthLm9yZyJ9.UE9sGYA_6bVNZ4peBZr_q_VqX15NvgI98A3vUzGVUHg",
      refreshToken:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NTMyOTk5MzcsImV4cCI6MTY4NDg1NzUzNywiYXVkIjoiNjI4OTM1NzFiZTRlOWNjMTQ5Y2E5ZDU3IiwiaXNzIjoiaHR0cHM6Ly9zYXNoaWthLm9yZyJ9.3npLvu1eAq9fp056jkLNmfFe9NzbpUxfrLxf9yaG0Go",
    },
    apiActions: {},
    notifications: {},
    bottomNavigation: {
      visibility: true,
      value: null,
      items: [
        { title: "Home", icon: "mdi-home-outline", to: "/" },
        { title: "MOU", icon: "mdi-notebook-edit-outline", to: "/mous" },
        // { title: "COUNTRIES", icon: "mdi-fireplace-off", to: "/countries" },
        {
          title: "INSTITUTES",
          icon: "mdi-home-city-outline",
          to: "/institutes",
        },
        {
          title: "APPROVERS",
          icon: "mdi-certificate-outline",
          to: "/approvers",
        },
        { title: "USERS", icon: "mdi-account-group-outline", to: "/users" },
      ],
    },
    leftSideBar: {},
    rightSideBar: {},
  }),

  mutations: {
    SET_BOTTOM_NAVIGATION_VALUE(state, payload) {
      state.bottomNavigation["value"] = payload;
    },

    SET_API_ACTION_STATUS_PENDING(state, payload) {
      const actions = { ...state.apiActions };
      const actionName = payload["name"];

      actions[actionName] = {
        status: null,
        startTime: Date.now(),
        endTime: null,
        message: null,
        isActive: true,
        isRed: false,
      };
      state.apiActions = actions;
    },

    SET_API_ACTION_STATUS_SUCCESS(state, payload) {
      const actions = { ...state.apiActions };
      const actionName = payload["name"];
      //   const action = actions[actionName];

      actions[actionName]["status"] = "success";
      actions[actionName]["endTime"] = Date.now();
      actions[actionName]["isActive"] = false;

      state.apiActions = actions;
    },

    SET_API_ACTION_STATUS_FAILED(state, payload) {
      const actions = { ...state.apiActions };
      const actionName = payload["name"];

      actions[actionName]["status"] = "failed";
      actions[actionName]["endTime"] = Date.now();
      actions[actionName]["message"] = payload.error;
      actions[actionName]["isActive"] = false;

      state.apiActions = actions;
    },

    SET_API_ACTION_STATUS_RED(state, payload) {
      state.apiActions[payload]["isRed"] = true;
    },

    REMOVE_API_ACTION(state, payload) {
      const apiActions = state.apiActions;
      delete apiActions[payload];
      state.apiActions = apiActions;
    },
  },

  actions: {
    setBottomNavigationValue({ commit }, payload) {
      commit("SET_BOTTOM_NAVIGATION_VALUE", payload);
    },

    setApiActionStatusRed({ commit }, payload) {
      commit("SET_API_ACTION_STATUS_RED", payload);
    },

    removeApiAction({ commit }, payload) {
      commit("REMOVE_API_ACTION", payload);
    },
  },
  getters: {
    apiActions: (state) => {
      return state.apiActions;
    },
    apiActionByName: (state) => (actionName) => {
      return state.apiActions[actionName];
    },
    bottomNavigationItems: (state) => {
      return state.bottomNavigation.items;
    },
    bottomNavigationValue: (state) => {
      return state.bottomNavigation.value;
    },
  },
};
